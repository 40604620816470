import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { caseRequests, } from "pages/Client/EmailCases/Components/EducationalMultistepForm/EducationalFormWhat/EducationalFormWhat";
function Request(props) {
    var text = props.text, selected = props.selected, active = props.active, onClick = props.onClick;
    return (_jsxs(Button, { sx: {
            backgroundColor: active ? "#4b637d1a" : "#F7F7F7",
            borderRadius: "12px",
            justifyContent: "flex-start",
        }, onClick: onClick, className: "w-full p-3 h-12 gap-3 ", children: [selected ? (_jsx(FontAwesomeIcon, { className: "w-5 h-5", icon: "check-circle", color: "gray" })) : (_jsx(FontAwesomeIcon, { className: "w-5 h-5", icon: "plus", color: "gray" })), text] }));
}
function RequestSelection(props) {
    var activeRequest = props.activeRequest, setActiveRequest = props.setActiveRequest;
    var watch = useFormContext().watch;
    var selected = watch("dsrs") || [];
    var onClick = function (request) {
        setActiveRequest(function (prev) {
            if ((prev === null || prev === void 0 ? void 0 : prev.type) === request.key) {
                return null;
            }
            var newRequest = selected.find(function (r) { return r.type === request.key; });
            if (newRequest) {
                return newRequest;
            }
            return {
                type: request.key,
                checked: true,
                inquiries: [
                    {
                        question: request.question,
                    },
                ],
                tags: ["SUFFICIENT_INFORMATION"],
            };
        });
    };
    return (_jsx("div", { className: "flex flex-col gap-3 text-prighterblue font-normal", children: caseRequests.map(function (request, index) { return (_jsx(Request, { text: request.value, selected: selected.some(function (r) { return r.type === request.key; }), active: (activeRequest === null || activeRequest === void 0 ? void 0 : activeRequest.type) === request.key, onClick: function () { return onClick(request); } }, index)); }) }));
}
export default RequestSelection;
