import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { captureMessage } from "@sentry/react";
import clsx from "clsx";
import DSLocation from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/DSLocation";
import DSIdentification from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/DSIdentification";
import ControllerProcessorDecision from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/ControllerProcessorDecision";
import AddProcessors from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/AddProcessors";
import RetainData from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/RetainData";
import OptOutComply from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/OptOutComply";
import InformThirdParty from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/InformThirdParty";
import IncorrectSources from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/IncorrectSources";
import CorrectDelete from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/CorrectDelete";
import AccessComply from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/AccessComply";
import DSDataUpload from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/DSDataUpload";
import WithholdReason from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/WithholdReason";
import { notify } from "store/app";
import tolgee from "services/translation";
import { useCase } from "pages/Client/Case/CaseContext";
import { getLatestActiveCheck, getStageChecks } from "helpers/case";
export default function CheckSteps() {
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var caseData = state.case;
    var checkStep = state.ui.check;
    var checks = getStageChecks(caseData.case_checks, state.ui.stage === "HANDLING" ? "HANDLING" : "PRE_CHECK");
    var activeStep = getLatestActiveCheck(checks) || "DS_LOCATION";
    var checkNames = (checks === null || checks === void 0 ? void 0 : checks.map(function (i) { return i.name; })) || [];
    var completed = function (checkName) {
        var _a;
        return !!((_a = checks.find(function (i) { return i.name === checkName; })) === null || _a === void 0 ? void 0 : _a.closed_at);
    };
    var renderActiveStep = function () {
        switch (checkStep) {
            case "DS_LOCATION":
                return _jsx(DSLocation, {});
            case "DS_IDENTIFICATION":
                return _jsx(DSIdentification, {});
            case "CONTROLLER_PROCESSOR":
                return _jsx(ControllerProcessorDecision, {});
            case "PROCESSORS_ADDED":
                return _jsx(AddProcessors, {});
            case "EXEMPTIONS":
                return _jsx(RetainData, {});
            case "INFORM_THIRD_PARTY":
                return _jsx(InformThirdParty, {});
            case "OPTOUT_COMPLY":
                return _jsx(OptOutComply, {});
            case "INCORRECT_SOURCES":
                return _jsx(IncorrectSources, {});
            case "CORRECT_DELETE":
                return _jsx(CorrectDelete, {});
            case "ACCESS_COMPLY":
                return _jsx(AccessComply, {});
            case "DS_DATA_UPLOAD":
                return _jsx(DSDataUpload, {});
            case "WITHHOLD_REASON":
                return _jsx(WithholdReason, {});
            default:
                notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" });
                captureMessage("".concat(tolgee.t({ key: "no_check_template", ns: "error" }), " \"").concat(activeStep, "\""));
                return null;
        }
    };
    return (checks && (_jsxs(Box, { sx: { width: "100%" }, "data-testid": "dsr-flow-check-data", children: [_jsx(Stepper, { nonLinear: true, activeStep: checks.findIndex(function (check) { return check.name === checkStep; }), connector: _jsx(_Fragment, {}), className: "flex justify-between p-1 px-6 border rounded-md shadow-xl border-brand-200 shadow-slate-100 h-fit bg-brand-200/80", children: checkNames.map(function (label) { return (_jsx(Step, { completed: completed(label), className: clsx("h-fit py-1 relative z-10 flex items-center rounded-md", {
                        "bg-success-100 shadow-lg shadow-success-700/10": checkStep === label && completed(label),
                        "bg-white shadow-lg shadow-slate-700/10": checkStep === label && !completed(label),
                    }), children: _jsxs("div", { className: "relative", children: [_jsx("div", { className: "hidden md:flex", children: _jsx(StepButton, { className: "!pl-8 !pr-10 !flex", icon: _jsx(_Fragment, {}), color: "inherit", onClick: function () { return dispatch({ type: "UPDATE_UI_CHECK", payload: label }); }, children: _jsxs("div", { className: "flex", children: [_jsx("div", { className: clsx({ "!text-success-700": completed(label) }), children: tolgee.t({ key: "step_title.".concat(label.toLowerCase()), ns: "dsr" }) }), completed(label) && (_jsx(FontAwesomeIcon, { className: "ml-2 text-success", icon: "circle-check", size: "lg" }))] }) }) }), checkStep === label && (_jsx("div", { className: clsx("h-0 w-0 top-0 right-0 border-y-[12px] translate-x-3 -translate-y-[2px] absolute border-y-transparent border-l-[5px]", completed(label) ? "border-l-success-100" : "border-l-white") })), _jsx("div", { className: "flex md:hidden", children: _jsx(StepButton, { color: "inherit", onClick: function () { return dispatch({ type: "UPDATE_UI_CHECK", payload: label }); } }) })] }) }, label)); }) }), _jsx("div", { className: "px-6", children: renderActiveStep() })] })));
}
