var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { T } from "@tolgee/react";
import { BaseModal } from "components";
import { api } from "services";
import { notify } from "store/app";
import tolgee from "services/translation";
import { productDict } from "const";
import { getFontColor } from "helpers/general";
import { PRIGHTER_URL } from "const/env";
var logoPosition = {
    TOP_RIGHT: "top-0 right-4",
    BOTTOM_RIGHT: "bottom-4 right-4",
    TOP_LEFT: "top-0 left-4",
    BOTTOM_LEFT: "bottom-4 left-4",
};
var textColor = {
    light: "black",
    dark: "white",
};
var bgColor = {
    light: "rgb(255 255 255 / 50%)",
    dark: "rgb(0 0 0 / 50%)",
};
export default function PublicCLP() {
    var _this = this;
    var _a, _b, _c, _d;
    var navigate = useNavigate();
    var publicId = useParams().publicId;
    var dispatch = useDispatch();
    var _e = useState(), settings = _e[0], setSettings = _e[1];
    var _f = useState({}), repLocations = _f[0], setRepLocations = _f[1];
    var _g = useState(), user = _g[0], setUser = _g[1];
    var _h = useState(false), open = _h[0], setOpen = _h[1];
    var _j = useState(), currentJurisdiction = _j[0], setCurrentJurisdiction = _j[1];
    var textStyle = { color: textColor[(_a = settings === null || settings === void 0 ? void 0 : settings.theme) === null || _a === void 0 ? void 0 : _a.toLowerCase()] };
    var handleClose = function () {
        setCurrentJurisdiction(null);
    };
    var handleOnClick = function (i) {
        setCurrentJurisdiction(i);
        setOpen(true);
    };
    useEffect(function () {
        document.title = tolgee.t({ key: "public_clp", ns: "public_clp" });
        fetchSettings();
        fetchRepLocations();
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var fetchSettings = function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.clp.getClpSettings(publicId)];
                case 1:
                    res = _a.sent();
                    if (!res) {
                        dispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                        return [2 /*return*/];
                    }
                    setSettings(res);
                    return [2 /*return*/];
            }
        });
    }); };
    var fetchRepLocations = function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.clp.getRepLocations(publicId)];
                case 1:
                    res = _a.sent();
                    if (!res) {
                        dispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                        return [2 /*return*/];
                    }
                    setRepLocations(res);
                    return [2 /*return*/];
            }
        });
    }); };
    var getUser = function () { return __awaiter(_this, void 0, void 0, function () {
        var res, currentUser;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.ping()];
                case 1:
                    res = _a.sent();
                    currentUser = res === null || res === void 0 ? void 0 : res.user;
                    if (currentUser && (currentUser === null || currentUser === void 0 ? void 0 : currentUser.email)) {
                        setUser(currentUser);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var companyName = (settings === null || settings === void 0 ? void 0 : settings.brand) ? settings.brand : user === null || user === void 0 ? void 0 : user.company_name;
    return (_jsxs("div", { style: { backgroundColor: settings === null || settings === void 0 ? void 0 : settings.clp_color_secondary }, className: "flex flex-col mx-auto h-screen items-center justify-center relative", children: [(settings === null || settings === void 0 ? void 0 : settings.logo_url) && (_jsx("figure", { className: "mt-4 image absolute ".concat(logoPosition[settings === null || settings === void 0 ? void 0 : settings.position]), children: _jsx("img", { alt: "company logo", style: { maxHeight: "300px", maxWidth: "300px" }, src: settings === null || settings === void 0 ? void 0 : settings.logo_url }) })), _jsx(BaseModal, { modalState: [open, setOpen], closeEvent: handleClose, children: _jsxs("div", { className: "space-y-4", children: [_jsx("div", { className: "font-bold", children: (_b = productDict[currentJurisdiction]) === null || _b === void 0 ? void 0 : _b.name }), _jsxs("div", { children: [_jsxs("div", { className: "font-bold", children: [_jsx(T, { ns: "generic", keyName: "address" }), ":"] }), _jsx("div", { children: (_c = repLocations[currentJurisdiction]) === null || _c === void 0 ? void 0 : _c.map(function (i) { return _jsx("div", { children: i }); }) })] })] }) }), _jsxs("div", { style: { backgroundColor: bgColor[(_d = settings === null || settings === void 0 ? void 0 : settings.theme) === null || _d === void 0 ? void 0 : _d.toLowerCase()] }, className: "flex flex-col box-outerlayout m-4 max-w-screen-lg w-full space-y-6 p-6", children: [_jsx("div", { className: "flex space-x-1 text-4xl font-bold mt-6", children: _jsxs("div", { style: textStyle, children: [companyName, " ", _jsx(T, { ns: "public_clp", keyName: "compliance_center" })] }) }), (settings === null || settings === void 0 ? void 0 : settings.clp_text) && _jsx("div", { style: textStyle, children: settings.clp_text }), _jsx("div", { children: _jsx(Button, { onClick: function () { return navigate("/dsr/".concat(publicId)); }, size: "large", style: {
                                backgroundColor: settings === null || settings === void 0 ? void 0 : settings.clp_color_primary,
                            }, className: "space-x-1 flex self-start", variant: "contained", children: _jsxs("div", { className: "font-bold", style: { color: getFontColor(settings === null || settings === void 0 ? void 0 : settings.clp_color_primary) }, children: [_jsx(T, { ns: "public_clp", keyName: "privacy_request" }), " ", companyName] }) }) }), _jsx("div", { className: "text-xs space-x-1 flex", children: _jsxs("div", { style: textStyle, children: [companyName, " ", _jsx(T, { ns: "public_clp", keyName: "collects_ip" })] }) }), _jsxs("div", { style: __assign(__assign({}, textStyle), { display: "inline" }), children: [_jsx("b", { children: _jsx(T, { ns: "public_clp", keyName: "prighter_group" }) }), " ", _jsx(T, { ns: "public_clp", keyName: "is_representative" }), " ", _jsx("b", { children: companyName }), " ", _jsx(T, { ns: "public_clp", keyName: "following_jusrisdictions" })] }), _jsx("div", { className: "flex space-x-8", children: repLocations &&
                            Object.keys(repLocations).map(function (i) {
                                var _a, _b;
                                var productKey = i;
                                return (_jsxs("div", { className: "cursor-pointer flex flex-col space-y-14 w-[110px]", onClick: function () { return handleOnClick(i); }, onKeyDown: function () { return handleOnClick(i); }, tabIndex: 0, role: "button", children: [_jsx("div", { className: "h-14 w-14", children: _jsx("img", { src: (_a = productDict[productKey]) === null || _a === void 0 ? void 0 : _a.image, alt: i }) }), _jsx("div", { className: "text-xs mt-2 font-bold", style: textStyle, children: (_b = productDict[productKey]) === null || _b === void 0 ? void 0 : _b.name })] }, i));
                            }) }), _jsxs("div", { children: [_jsxs("div", { className: "space-x-1 flex", children: [_jsxs("div", { style: textStyle, children: [_jsx(T, { ns: "public_clp", keyName: "website" }), ":"] }), _jsx("a", { style: textStyle, className: "font-bold", href: PRIGHTER_URL, children: PRIGHTER_URL })] }), _jsxs("div", { className: "space-x-1 flex", children: [_jsxs("div", { style: textStyle, children: [_jsx(T, { ns: "public_clp", keyName: "add_following" }), ":"] }), _jsxs("b", { style: textStyle, children: ["ID-", publicId] })] })] }), _jsxs("div", { className: "flex justify-between font-bold", children: [_jsx("div", { style: textStyle, children: _jsx(T, { ns: "public_clp", keyName: "powered_by" }) }), _jsxs("div", { className: "flex space-x-4", children: [_jsx("a", { style: textStyle, href: "/tbd", children: _jsx(T, { ns: "public_clp", keyName: "privacy_policy" }) }), _jsx("a", { style: textStyle, href: "/tbd", children: _jsx(T, { ns: "public_clp", keyName: "legal" }) })] })] })] })] }));
}
