import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { VerticalDataValue } from "components";
import { generateContactData, getDataSubject, getSender } from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";
import ConfirmInquiry from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/ConfirmInquiry";
import tolgee from "services/translation";
export default function DSIdentification() {
    var _a, _b;
    var _c = useCase(), state = _c[0], dispatch = _c[1];
    var caseData = state.case;
    var dataSubject = getDataSubject(caseData.case_actors);
    var sender = getSender(caseData.case_actors);
    var dsInfo = generateContactData(dataSubject);
    var senderInfo = generateContactData(sender);
    var emailForContact = ((_a = sender === null || sender === void 0 ? void 0 : sender.contact.contact_points.find(function (i) { return i.type === "EMAIL"; })) === null || _a === void 0 ? void 0 : _a.value) ||
        ((_b = dataSubject.contact.contact_points.find(function (i) { return i.type === "EMAIL"; })) === null || _b === void 0 ? void 0 : _b.value);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "grid grid-cols-2 gap-4", children: _jsxs("div", { className: "col-span-2 ", children: [_jsx(ConfirmInquiry, { handleSendInquiry: function () {
                                return dispatch({
                                    type: "SEND_INQUIRY",
                                    payload: ["identification_1"],
                                });
                            }, i18nSuffix: "ds_identification" }), _jsx("div", { className: "mt-4", children: tolgee.t({
                                key: "description_ds_identification",
                                ns: "dsr",
                            }) })] }) }), caseData.extra.acting_for !== "MYSELF" && (_jsxs(_Fragment, { children: [_jsx("h3", { className: "my-0 mb-1", children: tolgee.t({ key: "requester.".concat(caseData.extra.acting_for.toLowerCase()), ns: "dsr" }) }), _jsx("div", { className: "mb-4", children: _jsxs("div", { className: "grid @lg:grid-cols-2 gap-x-2 gap-y-2 border border-slate-200 rounded-lg -mx-2 px-3 py-3", children: [senderInfo.map(function (i, idx) { return (_jsx(VerticalDataValue, { title: tolgee.t({ key: i.key, ns: "dsr" }), value: i.value }, idx)); }), _jsx(VerticalDataValue, { title: tolgee.t({ key: "acting_for", ns: "dsr" }), value: tolgee.t({
                                        key: "ds_info.acting_for.".concat(caseData.extra.acting_for.toLowerCase()),
                                        ns: "ccpa",
                                    }) })] }) })] })), _jsxs(_Fragment, { children: [_jsx("h3", { className: "my-0 mb-1", children: tolgee.t({ key: "data_subject", ns: "dsr" }) }), _jsx("div", { className: "mb-4", children: _jsxs("div", { className: "grid  @lg:grid-cols-2 gap-x-2 gap-y-2 border border-slate-200 rounded-lg -mx-2 px-3 py-3 ", children: [dsInfo
                                    .filter(function (d) { return d.key !== "email_address"; })
                                    .map(function (i, idx) { return (_jsx(VerticalDataValue, { title: tolgee.t({ key: i.key, ns: "dsr" }), value: i.value }, idx)); }), _jsx(VerticalDataValue, { title: tolgee.t({ key: "email.for_contact", ns: "dsr" }), value: emailForContact }), _jsx(VerticalDataValue, { title: tolgee.t({ key: "email.for_identification", ns: "dsr" }), value: caseData.extra.email })] }) })] })] }));
}
