var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, TextField } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { caseRequests } from "pages/Client/EmailCases/Components/EducationalMultistepForm/EducationalFormWhat/EducationalFormWhat";
import { multilineStyle } from "pages/Client/EmailCases/Pages/styling";
function Request(props) {
    var _a;
    var request = props.request, selectedIndex = props.selectedIndex;
    var _b = useFormContext(), watch = _b.watch, setValue = _b.setValue, getValues = _b.getValues;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var dsrs = watch("dsrs") || [];
    var selected = selectedIndex !== -1 ? dsrs[selectedIndex] : null;
    var openButtonClicked = function (e) {
        e.preventDefault();
        e.stopPropagation();
        var requests = __spreadArray([], (getValues("dsrs") || []), true);
        if (selectedIndex === -1) {
            requests.push({
                checked: true,
                type: request.key,
                inquiries: [{ question: request.question, answer: "" }],
                tags: ["SUFFICIENT_INFORMATION"],
            });
            if (!open) {
                setOpen(true);
            }
        }
        else {
            if (open) {
                setOpen(false);
            }
            requests.splice(selectedIndex, 1);
        }
        setValue("dsrs", requests);
    };
    var handleTextChange = function (e) {
        var requests = __spreadArray([], (getValues("dsrs") || []), true);
        if (selectedIndex === -1) {
            requests.push({
                checked: true,
                type: request.key,
                inquiries: [{ question: request.question, answer: e.target.value }],
                tags: ["SUFFICIENT_INFORMATION"],
            });
        }
        else {
            requests[selectedIndex].inquiries[0].answer = e.target.value;
        }
        setValue("dsrs", requests);
    };
    return (_jsxs(Accordion, { expanded: open, onChange: function () { return setOpen(function (prev) { return !prev; }); }, disableGutters: true, sx: {
            backgroundColor: "transparent",
            boxShadow: "none",
            "&::before": {
                height: 0,
            },
        }, children: [_jsxs(AccordionSummary, { sx: {
                    // backgroundColor: active ? "#4b637d1a" : "#F7F7F7",
                    borderRadius: "12px",
                    "& .MuiAccordionSummary-content": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "12px",
                    },
                }, className: "w-full p-3 h-12  ", children: [_jsx("button", { type: "button", onClick: openButtonClicked, children: selected ? (_jsx(FontAwesomeIcon, { className: "w-5 h-5", icon: "check-circle", color: "gray" })) : (_jsx(FontAwesomeIcon, { className: "w-5 h-5", icon: "plus", color: "gray" })) }), request.value] }), _jsx(AccordionDetails, { children: _jsx(TextField
                // label="Multiline"
                , { 
                    // label="Multiline"
                    className: "w-full", label: "Data Subject reason for request...", sx: multilineStyle, value: ((_a = selected === null || selected === void 0 ? void 0 : selected.inquiries) === null || _a === void 0 ? void 0 : _a[0].answer) || "", onChange: handleTextChange, multiline: true, rows: 3 }) })] }));
}
function RequestSelection() {
    var watch = useFormContext().watch;
    var selected = watch("dsrs") || [];
    return (_jsx("div", { className: "flex flex-col gap-5 text-prighterblue font-normal", children: caseRequests.map(function (request, index) { return (_jsx(Request, { request: request, selectedIndex: selected.findIndex(function (r) { return r.type === request.key; }) }, index)); }) }));
}
export default RequestSelection;
