import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
// TODO @RENATO add colors to tailwind
export default function EmailCasesListItem(props) {
    var email = props.email, fetchEmailById = props.fetchEmailById, workflow = props.workflow;
    var navigate = useNavigate();
    // TODO @RENATO clean this function
    var handleClick = function (id) { return function (e) {
        switch (e.detail) {
            case 1:
                fetchEmailById(id);
                break;
            case 2:
                navigate("/email-cases/".concat(id, "/").concat(workflow));
                break;
            default:
                (function (count) {
                    throw new Error("Cannot determine click count:  ".concat(count));
                })(e.detail);
        }
    }; };
    return (_jsxs("button", { type: "button", onClick: handleClick(email.uuid), className: "flex items-center justify-between px-2 py-3 border-[1px] border-[#E3E3E3] w-full rounded-[4px] hover:bg-gray-200 focus:outline-none focus:ring focus:ring-prighterblue", children: [_jsxs("div", { className: "flex items-center gap-4", children: [_jsx("h5", { className: "text-xs font-bold font-inter", children: email.uuid }), _jsx("h5", { className: "text-xs font-light font-inter bg-[#E3E7EF] px-2 py-1 rounded-3xl", children: email.from_name })] }), _jsxs("div", { className: "flex items-center gap-4", children: [_jsx("p", { children: email.state }), _jsx("h5", { className: "text-xs font-light font-inter", children: email.received_at })] })] }, email.uuid));
}
