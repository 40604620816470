var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { notify } from "store/app";
import { useDebounce } from "helpers/hooks";
import { api } from "services";
import tolgee from "services/translation";
import ContactModal from "pages/Client/ContactDirectory/Components/ContactModal";
import { useCase } from "pages/Client/Case/CaseContext";
export default function AddProcessorField(data) {
    var _this = this;
    var state = useCase()[0];
    var dispatch = useDispatch();
    var caseActors = data.caseActors, handleReload = data.handleReload, locked = data.locked, contactType = data.contactType;
    var _a = useState([]), contactData = _a[0], setContactData = _a[1];
    var _b = useState(false), contactModal = _b[0], setContactModal = _b[1];
    var selectedData = useMemo(function () {
        return caseActors
            .filter(function (j) { return j.acting_as === contactType; })
            .map(function (i) {
            var _a, _b, _c;
            return ({
                created_at: i.contact.created_at,
                email: (_b = (_a = i.contact.contact_points) === null || _a === void 0 ? void 0 : _a.find(function (t) { return t.type === "EMAIL"; })) === null || _b === void 0 ? void 0 : _b.value,
                organization_name: i.contact.organization || "",
                person_name: (_c = i.contact) === null || _c === void 0 ? void 0 : _c.name,
                updated_at: i.contact.updated_at,
                uuid: i.uuid,
            });
        });
    }, [caseActors]);
    var _c = useState(""), search = _c[0], setSearch = _c[1];
    var debouncedSearchValue = useDebounce(search, 500);
    function fetchContactEmailData(props) {
        return __awaiter(this, void 0, void 0, function () {
            var order_by, page, per_page, search_term, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        order_by = props.order_by, page = props.page, per_page = props.per_page, search_term = props.search_term;
                        return [4 /*yield*/, api.contactDirectory.getContactEmail({
                                order_by: order_by,
                                page: page,
                                per_page: per_page,
                                search_term: search_term,
                            })];
                    case 1:
                        res = _a.sent();
                        if (res) {
                            setContactData(__spreadArray([{ uuid: "create_person" }], res.result, true));
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        fetchContactEmailData({
            order_by: "person_name",
            page: 1,
            per_page: 100,
            search_term: debouncedSearchValue,
        });
    }, [debouncedSearchValue]);
    var generateLabel = function (option) {
        var _a, _b;
        if (option.uuid === "create_person") {
            return "Create new person";
        }
        var result = [];
        result.push((_a = option.person_name) === null || _a === void 0 ? void 0 : _a.trim());
        result.push((_b = option.organization_name) === null || _b === void 0 ? void 0 : _b.trim());
        var label = result.filter(function (value) { return value; }).join(" / ");
        return "".concat(label, " (").concat(option.email, ")");
    };
    var handleAddProcessor = function (newContact) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.contactDirectory.dsrAddActor(state.case.uuid, {
                        name: newContact.person_name,
                        email: newContact.email,
                        acting_as: contactType,
                    })];
                case 1:
                    res = _a.sent();
                    if (res) {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "processor_added",
                                ns: "dsr",
                            }),
                            type: "SUCCESS",
                        }));
                        handleReload();
                    }
                    else {
                        dispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteProcessor = function (deletedContact) { return __awaiter(_this, void 0, void 0, function () {
        var contactUUID, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    contactUUID = caseActors.find(function (j) { var _a; return ((_a = j.contact) === null || _a === void 0 ? void 0 : _a.name) === deletedContact.person_name; }).uuid;
                    return [4 /*yield*/, api.contactDirectory.dsrDeleteContact(state.case.uuid, contactUUID)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "processor_deleted",
                                ns: "dsr",
                            }),
                            type: "SUCCESS",
                        }));
                        handleReload();
                    }
                    else {
                        dispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleChangeProcessor = function (_, __, reason, details) {
        var _a;
        if (((_a = details.option) === null || _a === void 0 ? void 0 : _a.uuid) === "create_person") {
            setContactModal(true);
            setSearch("");
            return;
        }
        if (reason === "selectOption") {
            handleAddProcessor(details.option);
        }
        else if (reason === "removeOption") {
            handleDeleteProcessor(details.option);
        }
        setSearch("");
    };
    var handleCloseModal = function () {
        fetchContactEmailData({
            order_by: "person_name",
            page: 1,
            per_page: 100,
            search_term: debouncedSearchValue,
        });
        setContactModal(false);
    };
    var filteredContactData = contactData.filter(function (contact) { return !selectedData.some(function (selected) { return selected.email === contact.email; }); });
    return (_jsxs(_Fragment, { children: [_jsx(Autocomplete, { disabled: locked, disableClearable: true, value: selectedData, multiple: true, filterSelectedOptions: true, options: filteredContactData, style: { marginTop: "10px" }, getOptionLabel: function (option) { return generateLabel(option); }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { label: contactType === "PROCESSOR"
                        ? tolgee.t({ key: "add_processor_contractor", ns: "ccpa" })
                        : tolgee.t({ key: "add_processor_third_party", ns: "ccpa" }), variant: "outlined" }))); }, onChange: handleChangeProcessor, inputValue: search, onInputChange: function (_, newSearch, reason) {
                    if (reason === "input") {
                        setSearch(newSearch);
                    }
                } }), _jsx(ContactModal, { modalState: [contactModal, handleCloseModal], emailRequired: true })] }));
}
