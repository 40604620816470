import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from "react";
import tolgee from "services/translation";
import { VerticalDataValue } from "components";
import { getBeautyDateTime, getFileIcon, getFileName } from "helpers";
import FileDownloadPreview from "components/DataDisplay/FileDownloadPreview";
import { generateContactData, generateUrl, getClosedInquiries, getDataSubject, getSender, } from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";
export default function DSInfo() {
    var _a, _b;
    var state = useCase()[0];
    var caseData = state.case;
    var files = caseData.files;
    var closedInquiries = getClosedInquiries(caseData.inquiries);
    var dataSubject = getDataSubject(caseData.case_actors);
    var sender = getSender(caseData.case_actors);
    var dsInfo = generateContactData(dataSubject);
    var senderInfo = generateContactData(sender);
    var otherInfo = useMemo(function () {
        var data = closedInquiries
            .filter(function (i) { return !["ip_address", "email", "acting_for"].includes(i.question_i18n.key); })
            .map(function (i) { return ({
            key: (i === null || i === void 0 ? void 0 : i.question_i18n) ? i === null || i === void 0 ? void 0 : i.question_i18n.key : i.question,
            value: (i === null || i === void 0 ? void 0 : i.answer_i18n)
                ? tolgee.t({ key: i === null || i === void 0 ? void 0 : i.answer_i18n.key, ns: i === null || i === void 0 ? void 0 : i.answer_i18n.ns })
                : i.answer,
            answeredAt: i === null || i === void 0 ? void 0 : i.answered_at,
        }); });
        return data.filter(function (i) { return !!i.value; });
    }, [closedInquiries]);
    var emailForContact = ((_a = sender === null || sender === void 0 ? void 0 : sender.contact.contact_points.find(function (i) { return i.type === "EMAIL"; })) === null || _a === void 0 ? void 0 : _a.value) ||
        ((_b = dataSubject.contact.contact_points.find(function (i) { return i.type === "EMAIL"; })) === null || _b === void 0 ? void 0 : _b.value);
    return (_jsxs("div", { className: "pt-5 mb-4 box-outerlayout @container", "data-testid": "dsr-info", children: [_jsx("div", { children: _jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "flex items-center", children: _jsx("h2", { children: tolgee.t({ key: "ds_information", ns: "dsr" }) }) }), _jsx("div", { className: "my-4", children: tolgee.t({ key: "ds_information_description", ns: "dsr" }) })] }) }), caseData.extra.acting_for !== "MYSELF" && (_jsxs(_Fragment, { children: [_jsx("h3", { className: "my-0 mb-1", children: tolgee.t({ key: "requester.".concat(caseData.extra.acting_for.toLowerCase()), ns: "dsr" }) }), _jsx("div", { className: "mb-4", children: _jsxs("div", { className: "grid @lg:grid-cols-2 gap-x-2 gap-y-2 border border-slate-200 rounded-lg -mx-2 px-3 py-3", children: [senderInfo.map(function (i, idx) { return (_jsx(VerticalDataValue, { title: tolgee.t({ key: i.key, ns: "dsr" }), value: i.value }, idx)); }), _jsx(VerticalDataValue, { title: tolgee.t({ key: "acting_for", ns: "dsr" }), value: tolgee.t({
                                        key: "ds_info.acting_for.".concat(caseData.extra.acting_for.toLowerCase()),
                                        ns: "ccpa",
                                    }) })] }) })] })), _jsxs(_Fragment, { children: [_jsx("h3", { className: "my-0 mb-1", children: tolgee.t({ key: "data_subject", ns: "dsr" }) }), _jsx("div", { className: "mb-4", children: _jsxs("div", { className: "grid  @lg:grid-cols-2 gap-x-2 gap-y-2 border border-slate-200 rounded-lg -mx-2 px-3 py-3 ", children: [dsInfo
                                    .filter(function (d) { return d.key !== "email_address"; })
                                    .map(function (i, idx) { return (_jsx(VerticalDataValue, { title: tolgee.t({ key: i.key, ns: "dsr" }), value: i.value }, idx)); }), _jsx(VerticalDataValue, { title: tolgee.t({ key: "email.for_contact", ns: "dsr" }), value: emailForContact }), _jsx(VerticalDataValue, { title: tolgee.t({ key: "email.for_identification", ns: "dsr" }), value: caseData.extra.email }), otherInfo.map(function (i, idx) { return (_jsxs(_Fragment, { children: [_jsx(VerticalDataValue, { title: i.key, value: i.value }, idx), (i === null || i === void 0 ? void 0 : i.answeredAt) && (_jsx("div", { className: "text-xs italic", children: getBeautyDateTime(i.answeredAt) }))] })); })] }) })] }), _jsxs(_Fragment, { children: [_jsx("h3", { className: "mb-1", children: tolgee.t({ key: "files", ns: "dsr" }) }), _jsx("div", { children: files === null || files === void 0 ? void 0 : files.map(function (file) { return (_jsx(FileDownloadPreview, { icon: getFileIcon(file.mime_type), url: generateUrl(caseData.uuid, file.uuid), fileName: getFileName(file), file: file })); }) })] })] }));
}
