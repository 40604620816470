var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, InputLabel } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import { GRAY_200 } from "const/color";
import { buttonStyle, inputStyle } from "pages/Client/EmailCases/Pages/styling";
export default function EducationalFormWhen(props) {
    var nextStep = props.nextStep, previousStep = props.previousStep;
    var _a = useFormContext(), watch = _a.watch, setValue = _a.setValue;
    var dateReceived = watch("received_at");
    return (_jsxs("div", { className: "flex flex-col gap-12", children: [_jsxs("div", { className: "flex flex-col gap-3", children: [_jsx("h2", { className: "m-0 text-black font-fire text-[28px]", children: "Section 4: When" }), _jsx("h2", { className: "m-0 text-black font-fire text-[28px] font-normal", children: "When was the request Received" })] }), _jsxs("div", { children: [_jsx(InputLabel, { id: "demo-simple-select-label", children: "Date Received" }), _jsx(LocalizationProvider, { dateAdapter: AdapterMoment, children: _jsx(DatePicker, { format: "DD/MM/YYYY", className: "w-full", sx: inputStyle, disableFuture: true, value: moment(dateReceived), onChange: function (newValue) { return setValue("received_at", newValue.toISOString()); } }) })] }), _jsxs("div", { className: "flex items-center gap-2", children: [_jsx(Button, { type: "button", variant: "contained", sx: __assign(__assign({}, buttonStyle), { backgroundColor: GRAY_200, color: "white" }), onClick: previousStep, children: "Back" }), _jsx(Button, { type: "button", variant: "contained", sx: buttonStyle, onClick: nextStep, children: "Next Section" }), _jsx("span", { className: "ml-2 text-[#B6B6B6]", children: "or press Enter" }), _jsx(FontAwesomeIcon, { icon: "circle-exclamation", color: "orange", size: "lg", className: "ml-3" }), _jsx("span", { tabIndex: 0, role: "button", className: "ml-2 underline decoration-solid text-prighterdark", children: "Missing Information" })] }), _jsxs("div", { children: [_jsx("h2", { children: "Education" }), _jsxs("div", { className: "box", children: [_jsx("p", { children: "Which dates are important" }), _jsx("p", { children: "Different Legal Deadlines" })] }), _jsx("h2", { children: "Examples" })] })] }));
}
