var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as Sentry from "@sentry/react";
import { api } from "services";
import { setUser } from "store/user";
import { getCurrentCaseStage, getLatestActiveCheck, getStageChecks } from "helpers/case";
import { store } from "store";
import { notify } from "store/app";
import tolgee from "services/translation";
export var fetchUserData = function () {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.getUser()];
                case 1:
                    res = _a.sent();
                    if (res === null || res === void 0 ? void 0 : res.email) {
                        dispatch(setUser(res));
                        Sentry.setUser({ id: res.uuid, email: res.email });
                        return [2 /*return*/, true];
                    }
                    return [2 /*return*/, false];
            }
        });
    }); };
};
export var fetchCaseData = function () {
    return function (dispatch_1, uuid_1) {
        var args_1 = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args_1[_i - 2] = arguments[_i];
        }
        return __awaiter(void 0, __spreadArray([dispatch_1, uuid_1], args_1, true), void 0, function (dispatch, uuid, reloadUI) {
            var res, currentStage, checks, activeCheck;
            if (reloadUI === void 0) { reloadUI = true; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.getCase(uuid)];
                    case 1:
                        res = _a.sent();
                        if (res.uuid) {
                            dispatch({ type: "FETCH_SUCCESS", payload: res });
                            if (reloadUI) {
                                if (!["CLOSED", "DONE"].includes(res.state)) {
                                    currentStage = getCurrentCaseStage(res);
                                    dispatch({ type: "UPDATE_UI_STAGE", payload: currentStage });
                                    if (["PRE_CHECK", "HANDLING"].includes(currentStage)) {
                                        checks = getStageChecks(res.case_checks, currentStage);
                                        activeCheck = getLatestActiveCheck(checks);
                                        dispatch({ type: "UPDATE_UI_CHECK", payload: activeCheck });
                                    }
                                }
                                else {
                                    dispatch({ type: "UPDATE_UI_STAGE", payload: "HANDLING_REVIEW" });
                                }
                            }
                        }
                        else {
                            dispatch({ type: "FETCH_FAIL" });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
};
export var finalizeCheck = function () {
    return function (dispatch, caseUuid, completedCheck, decisionName) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.closeCheck(caseUuid, {
                        check_name: completedCheck,
                        decision_name: decisionName || null,
                    })];
                case 1:
                    res = _a.sent();
                    if (res) {
                        store.dispatch(notify({
                            message: tolgee.t({ key: "check_finished_successfully", ns: "dsr" }),
                            type: "SUCCESS",
                        }));
                        fetchCaseData()(dispatch, caseUuid);
                    }
                    else {
                        store.dispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
};
export var reopenCheck = function () {
    return function (dispatch, caseUuid, checkName) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.reopenCheck(caseUuid, checkName)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        store.dispatch(notify({
                            message: tolgee.t({ key: "check_reopened_successfully", ns: "dsr" }),
                            type: "SUCCESS",
                        }));
                        fetchCaseData()(dispatch, caseUuid);
                    }
                    else {
                        store.dispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
};
