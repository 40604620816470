/*
  Returns a number of days left until the deadline.
  Returns a negative number if the deadline has passed.
*/
export var getDaysLeft = function (date) {
    // The number of milliseconds in one day
    var ONE_DAY = 1000 * 60 * 60 * 24;
    var currentDate = new Date();
    var deadlineDate = new Date(date);
    // Calculate the difference in milliseconds
    var differenceMs = deadlineDate - currentDate;
    return Math.floor(differenceMs / ONE_DAY);
};
export var getTimeDifferenceString = function (date, pastTime) {
    if (pastTime === void 0) { pastTime = false; }
    var currentDate = new Date();
    var pastDate = new Date(date);
    var timeDifference = pastTime ? currentDate - pastDate : pastDate - currentDate;
    var seconds = Math.floor(timeDifference / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    var weeks = Math.floor(days / 7);
    var months = Math.floor(days / 30.44); // Approximate months as 30.44 days
    var years = Math.floor(days / 365.25); // Approximate years as 365.25 days
    if (years > 0) {
        return "".concat(years, "y");
    }
    if (months > 0) {
        return "".concat(months, "mo");
    }
    if (weeks > 0) {
        return "".concat(weeks, "w");
    }
    if (days > 0) {
        return "".concat(days, "d");
    }
    if (hours > 0) {
        return "".concat(hours, "h");
    }
    return "".concat(minutes, "m");
};
export var getDaysLeftString = function (date) {
    var daysLeft = getDaysLeft(date);
    if (daysLeft > 0) {
        return daysLeft === 1 ? "1 Day" : "".concat(daysLeft, " Days");
    }
    return "0 Days";
};
