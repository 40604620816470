import { faker } from "faker";
import { DEV_ENV } from "const/env";
var initialCcpaObject = [
    {
        checked: DEV_ENV,
        type: "DSR_CCPA_DELETE",
        inquiries: [
            {
                question_i18n: {
                    key: "rights_form.request_refers_to_following_personal_info",
                    ns: "ccpa",
                    lang: "en",
                },
                answer: faker.lorem.words(20),
            },
        ],
    },
    {
        checked: false,
        type: "DSR_CCPA_ACCESS_GROUP",
        inquiries: [
            {
                checked: false,
                type: "DSR_CCPA_ACCESS_CATEGORIES",
                question_i18n: {
                    key: "rights_form.access_request_title",
                    ns: "ccpa",
                    lang: "en",
                },
                answer_i18n: {
                    key: "rights_form.categories",
                    ns: "ccpa",
                },
            },
            {
                checked: false,
                type: "DSR_CCPA_ACCESS",
                question_i18n: {
                    key: "rights_form.my_request_concerns_specific_info",
                    ns: "ccpa",
                    lang: "en",
                },
                answer: "",
            },
            {
                checked: false,
                type: "DSR_CCPA_ACCESS_SHARED",
                question_i18n: {
                    key: "rights_form.access_request_title",
                    ns: "ccpa",
                    lang: "en",
                },
                answer_i18n: {
                    key: "rights_form.business_sells_info",
                    ns: "ccpa",
                },
            },
        ],
    },
    {
        checked: false,
        type: "DSR_CCPA_CORRECT",
        inquiries: [
            {
                question_i18n: {
                    key: "rights_form.request_related_to_following_personal_information",
                    ns: "ccpa",
                },
                answer: "",
            },
            {
                question_i18n: {
                    key: "rights_form.personal_information_is_wrong_or_incomplete_because",
                    ns: "ccpa",
                },
                answer: "",
            },
            {
                question_i18n: {
                    key: "rights_form.amend_the_personal_information_as_follows",
                    ns: "ccpa",
                },
                answer: "",
            },
        ],
    },
    { checked: false, type: "DSR_CCPA_OPTOUT", inquiries: [] },
    { checked: false, type: "DSR_CCPA_LIMIT", inquiries: [] },
];
export var ccpaFormInitialValues = {
    type: "DSR_CCPA",
    dsrs: initialCcpaObject,
    actors: [
        {
            name: DEV_ENV ? faker.person.fullName() : null,
            email: "",
            acting_for: "MYSELF",
            acting_as: "DATA_SUBJECT",
        },
        {
            acting_as: "SENDER",
            acting_for: DEV_ENV ? "MYSELF" : null,
            name: DEV_ENV ? faker.person.fullName() : null,
            email: DEV_ENV ? faker.internet.exampleEmail() : null,
            files: [],
        },
    ],
    identifiable_info: {
        username: "",
        email: DEV_ENV ? faker.internet.exampleEmail() : "",
        customer_number: "",
        other: "",
    },
    files: [],
    request_privacy_related: DEV_ENV,
};
