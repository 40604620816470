import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export function stringifyContactName(name, salutation) {
    if (!name) {
        return "No Name";
    }
    if (salutation) {
        return "".concat(salutation, ". ").concat(name);
    }
    return name;
}
export function stringifyContactNameAndCompany(personName, orgName, personSalutation) {
    if (!personName && !orgName) {
        return "No Information";
    }
    if (!personName) {
        return orgName;
    }
    var contactName = stringifyContactName(personName, personSalutation);
    if (!orgName) {
        return contactName;
    }
    return "".concat(contactName, ", ").concat(orgName);
}
export function stringifyContactNameAndMail(name, email, salutation) {
    if (!name && !email) {
        return "No Information";
    }
    if (!name) {
        return email;
    }
    var contactName = stringifyContactName(name, salutation);
    if (!email) {
        return contactName;
    }
    return "".concat(contactName, ", ").concat(email);
}
export function sanitizeAddresses(addresses) {
    return addresses.filter(function (item) { return item.city || item.street || item.country_iso || item.postal; });
}
export function sanitizeContactPoints(contactPoints) {
    return contactPoints.filter(function (cp) { return cp.value; });
}
export function stringifyBehalfOfContactName(senderName, dSName) {
    if (!senderName && !dSName) {
        return "No Name";
    }
    if (!senderName || !dSName) {
        return senderName || dSName;
    }
    return (_jsxs("span", { children: [_jsx("b", { children: senderName }), " on behalf of ", _jsx("b", { children: dSName })] }));
}
