import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

import { VerticalDataValue } from "components";
import tolgee from "services/translation";

L.Icon.Default.mergeOptions({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
});

export default function GeoLocation({ geoLocation, ipAddress, physicalAddress }) {
  const validGeoLocation = geoLocation && geoLocation.length === 2;
  const geolocationString = validGeoLocation ? `${geoLocation[0]}, ${geoLocation[1]}` : "";

  return (
    <div className="flex">
      <div className="flex flex-col space-y-2 w-1/2">
        <VerticalDataValue
          title={tolgee.t({ key: "address", ns: "dsr" })}
          value={physicalAddress || tolgee.t({ key: "no_address", ns: "dsr" })}
        />
        {ipAddress && (
          <VerticalDataValue title={tolgee.t({ key: "ip_address", ns: "dsr" })} value={ipAddress} />
        )}
        {validGeoLocation && (
          <VerticalDataValue
            title={tolgee.t({ key: "location", ns: "dsr" })}
            value={geolocationString}
          />
        )}
      </div>

      {validGeoLocation && (
        <div className="h-[250px] w-1/2">
          <MapContainer
            doubleClickZoom={false}
            closePopupOnClick={false}
            dragging={false}
            zoomSnap={false}
            zoomDelta={false}
            trackResize={false}
            touchZoom={false}
            scrollWheelZoom={false}
            style={{ height: "250px", width: "auto" }}
            center={geoLocation}
            zoom={13}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={geoLocation} />
          </MapContainer>
        </div>
      )}
    </div>
  );
}
