var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, TextField } from "@mui/material";
import { GRAY_200, PRIGHTER_HIGHLIGHT } from "const/color";
import { buttonStyle, multilineStyle } from "pages/Client/EmailCases/Pages/styling";
export default function EducationalFormAdditional(props) {
    var previousStep = props.previousStep;
    return (_jsxs("div", { className: "flex flex-col gap-12", children: [_jsxs("div", { className: "flex flex-col gap-3", children: [_jsx("h2", { className: "m-0 text-black font-fire text-[28px]", children: "Section 5: Additional information" }), _jsx("h2", { className: "m-0 text-black font-fire text-[28px] font-normal", children: "Any additional information regarding the request" })] }), _jsx(TextField, { label: "Additional information", multiline: true, sx: multilineStyle, rows: 3 }), _jsxs("div", { className: "flex items-center gap-2", children: [_jsx(Button, { type: "button", variant: "contained", sx: __assign(__assign({}, buttonStyle), { backgroundColor: GRAY_200, color: "white" }), onClick: previousStep, children: "Back" }), _jsx(Button, { type: "submit", variant: "contained", sx: __assign(__assign({}, buttonStyle), { backgroundColor: PRIGHTER_HIGHLIGHT, color: "white" }), children: "Save Request" }), _jsx("span", { className: "ml-2 text-[#B6B6B6]", children: "or press Enter" }), _jsx(FontAwesomeIcon, { icon: "circle-exclamation", color: "orange", size: "lg", className: "ml-3" }), _jsx("span", { tabIndex: 0, role: "button", className: "ml-2 underline decoration-solid text-prighterdark", children: "Missing Information" })] }), _jsxs("div", { children: [_jsx("h2", { children: "Education" }), _jsxs("div", { className: "box", children: [_jsx("p", { children: "Which dates are important" }), _jsx("p", { children: "Different Legal Deadlines" })] }), _jsx("p", { children: "Different Legal Jurisdictions" }), _jsx("h2", { children: "Examples" })] })] }));
}
