import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { caseRequests } from "pages/Client/EmailCases/Components/EducationalMultistepForm/EducationalFormWhat/EducationalFormWhat";
function RequestItem(props) {
    var _a;
    var request = props.request;
    var _b = useFormContext(), setValue = _b.setValue, watch = _b.watch;
    var dsrs = watch("dsrs") || [];
    var handleChange = function (_event, value) {
        var requestIndex = dsrs.findIndex(function (r) { return r.type === request.type; });
        setValue("dsrs.".concat(requestIndex, ".tags.0"), value);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "w-full text-sm p-3 items-center rounded-[12px] h-12 gap-3 border border-[#549444] bg-[#E6FAE1] flex  ", children: [_jsx(FontAwesomeIcon, { icon: "check-circle", color: "gray", size: "lg" }), (_a = caseRequests.find(function (r) { return r.key === request.type; })) === null || _a === void 0 ? void 0 : _a.value] }), _jsx(FormControlLabel, { sx: {
                    margin: 0,
                }, control: _jsxs(RadioGroup, { "aria-labelledby": "demo-controlled-radio-buttons-group", name: "controlled-radio-buttons-group", value: request.tags[0], row: true, onChange: handleChange, children: [_jsx(FormControlLabel, { value: "SUFFICIENT_INFORMATION", control: _jsx(Radio, { checkedIcon: _jsx(FontAwesomeIcon, { icon: "check-circle", className: "w-5 h-5 text-prighterblue" }) }), label: "Yes" }), _jsx(FormControlLabel, { value: "INSUFFICIENT_INFORMATION", control: _jsx(Radio, { checkedIcon: _jsx(FontAwesomeIcon, { icon: "check-circle", className: "w-5 h-5 text-prighterblue" }) }), label: "No" })] }), label: "Sufficient Information", labelPlacement: "start", id: "demo-controlled-radio-buttons-group", className: "gap-4" })] }));
}
function IdentificationCheck(props) {
    var setStep = props.setStep;
    var watch = useFormContext().watch;
    var requests = watch("dsrs") || [];
    return (_jsxs("div", { className: "flex flex-col gap-3 text-prighterblue font-normal w-full", children: [_jsx("div", { className: "flex flex-col gap-4 items-start", children: requests
                    .filter(function (req) { return req.checked; })
                    .map(function (request, index) { return (_jsx(RequestItem, { request: request }, index)); }) }), _jsxs(Button, { sx: {
                    backgroundColor: "#F7F7F7",
                    borderRadius: "12px",
                    fontWeight: "normal",
                }, onClick: function () {
                    setStep(1);
                }, className: "w-full p-3 h-[92px] gap-3 ", children: [_jsx(FontAwesomeIcon, { icon: "plus", color: "gray", size: "lg" }), "Add another Data Subject request"] })] }));
}
export default IdentificationCheck;
