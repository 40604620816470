import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormControl } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import { BaseSelectField } from "components";
import { buttonStyle, selectStyle } from "pages/Client/EmailCases/Pages/styling";
export default function EducationalFormWhere(props) {
    var nextStep = props.nextStep;
    var _a = useFormContext(), watch = _a.watch, setValue = _a.setValue, errors = _a.formState.errors;
    var type = watch("type");
    return (_jsxs("div", { className: "flex flex-col gap-12", children: [_jsxs("div", { className: "flex flex-col gap-3", children: [_jsx("h2", { className: "m-0 text-black font-fire text-[28px]", children: "Section 1: Where" }), _jsx("h2", { className: "m-0 text-black font-fire text-[28px] font-normal", children: "Is the Data Subject requesting from California?" })] }), _jsx(FormControl, { fullWidth: true, className: "flex flex-col gap-3", children: _jsx(BaseSelectField, { error: !!(errors === null || errors === void 0 ? void 0 : errors.type), required: true, sx: selectStyle, state: [type, function (val) { return setValue("type", val); }], 
                    // label={tolgee.t({ key: "ds_info.acting_for", ns: "fadp" })}
                    options: {
                        DSR_CCPA: "Yes",
                        AUTHORITY: "No",
                    } }) }), _jsxs("div", { className: "flex items-center", children: [_jsx(Button, { type: "button", variant: "contained", sx: buttonStyle, onClick: nextStep, disabled: type !== "DSR_CCPA", children: "Next Section" }), _jsx("span", { className: "ml-2 text-[#B6B6B6]", children: "or press Enter" }), _jsx(FontAwesomeIcon, { icon: "circle-exclamation", color: "orange", size: "lg", className: "ml-3" }), _jsx("span", { tabIndex: 0, role: "button", className: "ml-2 underline decoration-solid text-prighterdark", children: "Missing Information" })] }), _jsxs("div", { children: [_jsx("h2", { children: "Education" }), _jsx(Link, { to: "/#", children: "What is Legal Framework?" }), _jsxs("div", { className: "box", children: [_jsx("p", { children: "Legal Framework" }), _jsx("p", { children: "Different countries have different laws and regulations related to data protection, privacy, and other legal matters. By identifying the location, it will indicate different laws and regulations of said country which would likely apply to the client in terms of data protection and other legal aspects related to online activities." }), _jsx("p", { children: "Legal Framework" }), _jsx("p", { children: "Different countries have different laws and regulations related to data protection, privacy, and other legal matters. By identifying the location, it will indicate different laws and regulations of said country which would likely apply to the client in terms of data protection and other legal aspects related to online activities." })] }), _jsx("p", { children: "Different Legal Jurisdictions" }), _jsx("h2", { children: "Education" })] })] }));
}
