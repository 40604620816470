import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { caseRequests } from "pages/Client/EmailCases/Components/EducationalMultistepForm/EducationalFormWhat/EducationalFormWhat";
function RequestFinish(props) {
    var _a;
    var activeRequest = props.activeRequest, addCurrentRequest = props.addCurrentRequest, goToFirstStep = props.goToFirstStep;
    return (_jsxs("div", { className: "flex flex-col gap-3 text-prighterblue font-normal", children: [_jsx("div", { className: "flex flex-col gap-4", children: _jsxs("div", { className: "w-full text-sm p-3 items-center rounded-[12px] h-12 gap-3 border border-[#549444] bg-[#E6FAE1] flex  ", children: [_jsx(FontAwesomeIcon, { icon: "check-circle", color: "gray", size: "lg" }), (_a = caseRequests.find(function (r) { return r.key === (activeRequest === null || activeRequest === void 0 ? void 0 : activeRequest.type); })) === null || _a === void 0 ? void 0 : _a.value] }) }), _jsxs(Button, { onClick: function () {
                    addCurrentRequest();
                    goToFirstStep();
                }, sx: {
                    backgroundColor: "#F7F7F7",
                    borderRadius: "12px",
                    fontWeight: "normal",
                }, className: "w-full p-3 h-[92px] gap-3 ", children: [_jsx(FontAwesomeIcon, { icon: "plus", color: "gray", size: "lg" }), "Add another Data Subject request"] })] }));
}
export default RequestFinish;
