import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ConfirmInquiry from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/ConfirmInquiry";
import GeoLocation from "pages/Client/Case/DSR/ClientView/Components/GeoLocation";
import tolgee from "services/translation";
import { useCase } from "pages/Client/Case/CaseContext";
export default function DSLocation() {
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "flex flex-col space-y-4", children: [_jsx("div", { children: _jsx(ConfirmInquiry, { handleSendInquiry: function () {
                            return dispatch({
                                type: "SEND_INQUIRY",
                                payload: ["location_1", "location_2", "location_3", "location_4"],
                            });
                        }, i18nSuffix: "location" }) }), _jsx("div", { children: tolgee.t({
                        key: "description_ds_location",
                        ns: "dsr",
                    }) }), _jsx(GeoLocation, { geoLocation: state.case.extra.ip_geo_location, ipAddress: state.case.extra.ip_address, physicalAddress: state.case.extra.address })] }) }));
}
